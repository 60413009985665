<template>
   <v-theme-provider>
    <base-section
      id="news-latest"
      class="grey lighten-4 mt-n6"
      space="0"
    >
    <div 
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-center">
    <v-container
    >
    <base-section-heading title="#PenjaminanAman" />
      <v-row
        no-gutters
      >
        <v-col
          v-for="item in items"
          :key="item.icon"
          cols="12"
          xs="12"
          md="4"
          sm="6"
        >
          <v-card
            outlined
            tile
            height = "130"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title><span><v-icon x-large color="fourth">{{item.icon}}</v-icon> <span class="title font-weight-bold">{{item.title}}</span></span></v-card-title>
                <v-card-subtitle><span class="body-2 font-weight-bold">{{item.text}}</span></v-card-subtitle>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
  </base-section>
</v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      items: [
        {
          icon: 'mdi-professional-hexagon',
          title: 'Tenaga Ahli Profesional',
          text: 'Memiliki tenaga ahli professional, bersertifikat dan berpengalaman di industri penjaminan.',
          textcolor: 'white',
          align: 'start'
        },
        {
          icon: 'mdi-clock-fast',
          title: 'Sertifikat dan Klaim Cepat',
          text: 'Digital Proses, seluruh proses dari pembentukkan sertifikat dan klaim dilakukan melalui digital',
          textcolor: 'white',
          align: 'center'
        },
        {
          icon: 'mdi-lock-check',
          title: 'Penjaminan Aman',
          text: 'Keamanan Data, Sertifikat dan lain-lain',
          textcolor: 'white',
          align: 'end'
        },
      ],
    }),
  }
</script>
